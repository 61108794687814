// Styles for the _thank-you-panel.slim template
// Include these styles AFTER the shared template styles and BEFORE any
// project-specific overwrites

.lead-constructor__panel--thank-you-panel {
  @include media($width-l) {
    height: 150px; }

  // overwrite shared styles to keep panel always centered
  &.lead-constructor__panel {
    @include media($width-l) {
      text-align: center; } }

  .lead-constructor__body {
    @include type(16, 22);
    max-width: 550px;
    margin: 0 auto; }

  .lead-constructor__heading {
    font-size: 2em; }

  .thank-you-panel__phone-number {
    display: none;
    @include type(36, 32);
    margin-top: $grid-gutter/2;
    text-decoration: none;
    color: edge-color(hot-pink);
    font-weight: $font-secondary-weight-medium;

    @include media($width-l) {
      display: inline-block; } }

  .thank-you-panel__phone-button {
    display: inline-block;
    margin-top: $grid-gutter/2;

    svg {
      display: inline-block;
      vertical-align: text-bottom;
      height: 20px;
      width: 20px;
      margin-right: -4px; }

    @include media($width-l) {
      display: none; } } }
