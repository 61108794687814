.lead-constructor__panel--checkboxes {
  position: relative;

  .lead-constructor__content {
    @include media($width-l) {
      top: 50%; } } // proper vertical centering because the panel gets taller than most

  .lead-constructor__helpertext {
    font-style: italic;
    margin: $grid-gutter/2 0 0; }

  .lead-constructor__answer-block {
    margin: $grid-gutter auto;
    max-width: $max-button-width;

    @include media($width-l) {
      max-width: none; }

    &.two-column {
      @include media($width-m) {
        break-inside: avoid;
        column-count: 2;
        max-width: 500px; }

      @include media($width-l) {
        max-width: none; } }

    &.three-column {
      @include media($width-m) {
        break-inside: avoid;
        column-count: 3;
        max-width: 500px; }

      @include media($width-l) {
        max-width: none; } } }

  input.lead-constructor__checkbox {
    // the actual input elements are hidden and designed versions are shown via pseudo elements
    display: none;

    // Shows the designed checkmark in the box when option is selected
    &:checked + label:after {
      content: ''; } }

  label.lead-constructor__button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-transform: none;
    margin: 0 auto $grid-gutter/2;
    width: 100%;
    text-align: left;
    // Magic pixels to create space for visual checkbox pseudo elements
    padding-left: 36px;
    padding-top: 4px;

    @include media($width-l) {
      letter-spacing: normal;
      @include type(24, 36); }

    // Background of designed checkbox
    &:before {
      content: '';
      position: absolute;
      display: block;
      background-color: color(white);
      box-shadow: inset 0 1px 3px color(bayoux);
      height: 22px;
      width: 22px;
      left: 0;
      top: 0;
      @include media($width-l) {
        top: 10px; } }

    // Designed checkmark
    &:after {
      content: none; // Hidden by default until input element is checked
      position: absolute;
      display: block;
      transform: rotate(-45deg);
      // Magic pixels are used to create the actual checkmark
      height: 6px;
      width: 14px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      left: 4px;
      top: 6px;
      @include media($width-l) {
        left: 4px;
        top: 16px; } } } }
