@import '~vt-frontend-engine/app/styles/principles/variables/all-edge';
@import '~vt-frontend-engine/app/styles/principles/mixins/all-edge';

.vt-leadform-wizard--vt_auth_tutoring_hagrid,
.vt-leadform-wizard--vt_vsx_vt2_hagrid,
.vt-leadform-wizard--vt_classes_grades_hagrid {

  // Variables
  @import '../../../variables/buttons';
  @import '../../../variables/vt2-colors';
  @import '../../../variables/vt2-font-stack';
  @import '../../../variables/vt2-button';

  // Placeholders
  @import '../../../placeholders/antialias';

  // Components
  @import '../../../components/progress_indicators/progress-bar';
  @import '../../../components/templates/welcome';
  @import '../../../components/templates/radio-buttons';
  @import '../../../components/templates/form-fields';
  @import '../../../components/templates/multi-select';
  @import '../../../components/templates/thank-you-panel';
  @import '../../../overwrites/wizard/basics/vt2_theme';

  .leadform.leadform--wizard {
    background: #ffffff; }

  .leadform.leadform--wizard {
    padding-bottom: 20px;

    @include media($width-m) {
      padding-bottom: 0; } }

  .progress-wrapper {
    display: block;
    margin-top: 50px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;

    @include media($width-l) {
      display: none; } }

  .lead-constructor__content {
    padding-top: 30px;

    @include media($width-l) {
      padding-top: 0; } }

  //thank-you-panel-link
  .lead-constructor__panel--thank-you-panel-link {
    font-family: $font-primary;

    .lead-constructor__heading.edge-heading {
      @extend %antialias;
      font-family: $font-primary;
      font-size: 2rem;
      font-weight: 300;
      color: $vt2-black;
      padding-bottom: 20px; }

    .thank-you-panel-link__message {
      @extend %antialias;
      font-size: 18px;
      color: $vt2-black; }

    .edge-heading.thank-you-panel__phone-number {
      @extend %antialias;
      font-family: $font-primary;
      font-size: 24px;
      font-weight: 500;
      color: $vt2-blue;
      text-decoration: none; } } }

.vt-leadform-wizard--vt_vsx_vt2_hagrid {
  .lead-constructor__panel--thank-you-panel-link {
    .lead-constructor__heading.edge-heading {
      font-size: 1.5em;
      font-weight: 700;

      @include media($width-m) {
        font-size: 2rem; } } } }
